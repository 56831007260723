.footer {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 350px;
  background: #02304e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.line1 {
  width: auto;
  height: 1px;
  background-color: #b9b9b9;
  position: absolute;
  left: 0;
  right: 0;
  margin-bottom: -240px; /* Adjust as needed */
}

.logo-left-footer {
  max-width: 500px;
  margin-top: -55px;
  margin-left: 30%;
  position: relative;
  overflow: hidden;
  transition: transform 0.8s ease-in-out;
}

.logo-left-footer:hover {
  transform: scale(1.2);
}

.logo-left-footer:hover img {
  cursor: pointer;
}

.center-section {
  position: absolute;
  left: 90%;
  transform: translate(-50%, 50%);
  display: flex;
  align-items: center;
  line-height: normal;
  margin-bottom: -250px;
}

.icons-tooltip {
  display: flex;
}

.icon-link {
  position: relative;
  display: block;
}

.icon-name {
  visibility: hidden;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  color: #cbcbcb;
  z-index: 99999;

  padding: 4px 8px;
  border-radius: 6px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}

.icon-link:hover .icon-name {
  visibility: visible;
  opacity: 1;
}

.icon-socials {
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.icon-socials:hover {
  transform: scale(1.5);
}

.icon-socialss {
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.icon-socialss:hover {
  transform: scale(1.5);
}
.icon-socialss:hover svg path:nth-child(1) {
  fill: #fff;
}
.icon-socialss svg path:nth-child(1) {
  fill: #fff;
}

#icon-ig {
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

#icon-ig:hover svg path:nth-child(2) {
  fill: #fff;
}

#icon-ig svg path:nth-child(2) {
  fill: #fff;
}

#icon-ig:hover {
  transform: scale(1.5);
  fill: #fff;
}

.icon-socials:hover svg path:nth-child(1) {
  fill: #fff;
}
.icon-socials svg path:nth-child(1) {
  fill: #fff;
}

.bottom-text {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  align-items: center;
  line-height: normal;
  margin-bottom: -265px;
}

.right-section {
  text-align: center;
  margin-top: 40px;
}
.svg-email-website {
  margin-bottom: 2%;
}

#right-text {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  line-height: normal;
  margin-top: -178px;
  margin-right: -200px;
  display: inline-block;
  vertical-align: top;
}

#rightone-text2 {
  color: #fff;
  font-size: 19px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: -22px;
  margin-top: 2px;
}

#rightone-text {
  color: #fff;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-align: left;
  line-height: normal;
  display: inline-block;
  margin-top: -50px;
  vertical-align: top;
  margin-right: 490px;
}

textarea {
  border: none;
  resize: vertical;
  outline: none;
  padding: 10px;
  font-weight: 300;
  font-size: 15px;
  box-sizing: border-box;
  width: 100%;
  height: 150px;
  border: 0.3px solid #599bc1;
}

.titles {
  color: #fff;
  text-decoration: none;
}

.titles:hover {
  color: #91e5f6;
}

a {
  color: #fff;
  text-decoration: none;
  font-weight: 400; /* Regular font weight */
  transition: color 0.3s ease-in-out !important;
}

a:hover {
  color: #045e9e;
  transform: scale(1.2) !important;

}

@media screen and (max-width: 1200px) {
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    font-family: "Poppins", sans-serif;
  }

  .footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: auto;
    width: auto;
  }
  .logo-left-footer {
    max-width: 250px !important;
    margin-top: 25px;
    margin-left: 0%;
    overflow: hidden;
    transition: transform 0.8s ease-in-out;
  }

  .center-section {
    position: absolute;
    left: 88%;
    transform: translate(-50%, 50%);
    display: flex;
    align-items: center;
    line-height: normal;
    margin-bottom: -575px;
  }

  .line1 {
    width: 101.8%;
    height: 1px;
    position: absolute;
    margin-left: -2%;
    margin-bottom: -550px !important;
  }

  .bottom-text {
    margin-bottom: -590px;
    margin-left: 50px;
  }

  #rightone-text {
    margin-top: 40px;
    margin-right: 50px;
    margin-left: -150px;
    margin-bottom: -68px;
  }

  #rightone-text2 {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-bottom: -19px !important;
    margin-top: 2px;
    margin-right: 247px;
  }

  #right-text {
    margin-right: 80px;
    margin-left: 450px;
    margin-bottom: 200px;
    margin-top: -90px;
  }
  .icon-socials:hover {
    transform: scale(1.8);
  }
  #icon-ig:hover {
    transform: scale(1.8);
    fill: #599bc1;
  }
}

@media (max-width: 820px) {
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: "Poppins", sans-serif;
  }

  .footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 780px;
    width: auto;
  }
  .logo-left-footer {
    max-width: 250px;
    margin-top: 15px;
    margin-left: 0%;
    overflow: hidden;
    transition: transform 0.8s ease-in-out;
  }

  .center-section {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    align-items: center;
    line-height: normal;
    margin-bottom: -640px;
  }

  .line1 {
    width: 101.8%;
    height: 1px;
    position: absolute;
    margin-left: -2%;
    margin-bottom: -600px !important;
  }

  .bottom-text {
    margin-bottom: -730px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-left: -1%;
    width: 100%;
  }

  #rightone-text {
    margin-top: 30px;
    margin-right: 0px;
    margin-left: 0px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: -68px;
  }

  #rightone-text2 {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-bottom: -19px !important;
    margin-top: 2px;
    margin-left: 0px;
    text-align: center;
    align-items: center;
    width: 100%;
  }

  #right-text {
    margin-right: 0px;
    margin-left: 0%;
    margin-bottom: 100px;
    margin-top: 67px;
    text-align: center;
    align-items: center;
  }
}
 