::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: #CBCBCB;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: #535353;
  border-radius: 15px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #202020;
  border-radius: 15px;
  cursor: pointer;
}