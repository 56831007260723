/* MainPage/MainPage.css */
.main-page {
     margin-top: 170px;
     margin-left: 50px;
    margin-bottom: 400px;
    width: 50%;
  }
  
  .logo {
    width: 150px;
    float: right;
  }
  
  .heading {
    font-size: 24px;
    font-weight: bold;
  }
  
  .content {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
  }
  
  .info {
    text-align: left;
  }
  
  .info h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .info p {
    margin-bottom: 10px;
  }
  
  .info ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  @media (max-width: 768px) {
    .logo {
      width: 100px;
      float: none;
      margin: 0 auto;
      display: block;
    }
  
    .heading {
      font-size: 20px;
    }
  
    .content {
      font-size: 14px;
    }
  
    .info {
      font-size: 14px;
    }
    .main-page, .info {
   align-items: center !important; 
   justify-content: center !important;
        text-align: left;
        width: 80% !important;
        }
   
     
  }
  
@media screen and (max-width: 550px) {
    .main-page, .info {
        align-items: center !important; 
        justify-content: center !important;
             text-align: left;
             width: 80% !important;
             margin-top: 80px;
             }
  }
  