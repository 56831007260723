.header {
  display: flex;
  height: 55px;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: #02304e;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
}

/*
.logo-bidtype {
  color: #b49662;
  font-family: "Tenor Sans", sans-serif;
  font-size: 30px;
  font-weight: 400;
  margin-right: 10%;
  line-height: normal;
  margin-left: 1.5%;
  transition: color 0.8s ease-in-out, transform 0.8s ease-in-out;
}

.logo-bidtype:hover {
  transform: scale(1.12);
}*/

@keyframes slideIn {
  0% {
    transform: translateX(-100%) scale(0.5);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}

.logo-bidtype {
  flex-shrink: 0;
  margin-left: 2.3% !important;
  margin-right: -2%;
  animation: slideIn 0.8s ease-in-out forwards !important;;
  transition: color 0.5s ease-in-out, transform 0.5s ease-in-out !important;
}

.logo-bidtype:hover {
  transform: scale(1.25); /* Slightly smaller scaling on hover */
}

.logo-bidtype1 {
  display: flex;
  width: 50px;
  height: auto;
  justify-content: center;
  margin-top: 0px;
}

.three-name-header {
  width: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
}

.Buy-2 {
  display: flex;
  font-size: 19px;
  width: 100%;
  align-items: center;
  margin: 0 auto; /* Center horizontally */
  font-family: "Poppins";
  color: #b9b9b9;
  transition: color 0.5s ease-in-out, transform 0.5s ease-in-out !important;
}

.Buy-2:hover {
  color: #ffffff;
  transform: scale(1.5);
  font-weight: 900;
}
